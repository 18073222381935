<template>
    <div class="content">
        <section id="section-booking" class="section booking customCheckout">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="title text-center">
                            Korábbi rendeléseim
                        </h1>
                        <div class="table-responsive">
                            <table class="table table-dark">
                                <thead>
                                    <tr>
                                        <th scope="col">Dátum</th>
                                        <th scope="col">Cím</th>
                                        <th scope="col">Tételek</th>
                                        <th scope="col">Összeg</th>
                                        <th scope="col">Újrarendelés</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(item, index) in model.data"
                                        v-bind:key="'previous-orders-' + index"
                                    >
                                        <td>{{ item.created_at }}</td>
                                        <td>
                                            {{ item.shipping_lastname }}
                                            {{ item.shipping_firstname
                                            }}<br />{{ item.shipping_city_name
                                            }}<br />{{ item.shipping_address }}
                                        </td>
                                        <td>
                                            <div
                                                v-for="(item2,
                                                index2) in item.items"
                                                v-bind:key="
                                                    'order-items-' + index2
                                                "
                                            >
                                                <b>{{ item2.product.name }}</b
                                                ><br />
                                                <small
                                                    v-html="item2.extras"
                                                ></small>
                                                <br />
                                                <small
                                                    v-html="item2.comment"
                                                ></small>
                                            </div>
                                        </td>
                                        <td>
                                            {{ item.order_total_formatted }}
                                        </td>
                                        <td>
                                            <button
                                                v-if="shop && shop.is_open"
                                                style="border-radius: 5px; float: none !important;"
                                                class="menu-btn"
                                                v-on:click="doOrder(item.id)"
                                            >
                                                Rendelés
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <nav
                            aria-label="Page navigation example"
                            v-if="model && model.meta && model.links"
                        >
                            <ul class="pagination">
                                <li class="page-item">
                                    <button
                                        class="page-link"
                                        href="#"
                                        aria-label="Előző"
                                        v-on:click="
                                            paginate(
                                                model.meta.current_page - 1
                                            )
                                        "
                                        :disabled="model.meta.current_page < 2"
                                    >
                                        <span aria-hidden="true">&laquo;</span>
                                        <span class="sr-only">Előző</span>
                                    </button>
                                </li>
                                <li
                                    class="page-item"
                                    v-if="model.meta.current_page > 1"
                                    v-on:click="
                                        paginate(model.meta.current_page - 1)
                                    "
                                >
                                    <button class="page-link" href="#">
                                        {{ model.meta.current_page - 1 }}
                                    </button>
                                </li>
                                <li class="page-item active">
                                    <button class="page-link">
                                        {{ model.meta.current_page }}
                                    </button>
                                </li>
                                <li
                                    class="page-item"
                                    v-if="
                                        model.meta.current_page !==
                                            model.meta.last_page
                                    "
                                >
                                    <button
                                        class="page-link"
                                        href="#"
                                        v-on:click="
                                            paginate(
                                                model.meta.current_page + 1
                                            )
                                        "
                                    >
                                        {{ model.meta.current_page + 1 }}
                                    </button>
                                </li>
                                <li class="page-item">
                                    <button
                                        class="page-link"
                                        href="#"
                                        aria-label="Következő"
                                        v-on:click="
                                            paginate(
                                                model.meta.current_page + 1
                                            )
                                        "
                                        :disabled="
                                            model.meta.current_page ===
                                                model.meta.last_page
                                        "
                                    >
                                        <span aria-hidden="true">&raquo;</span>
                                        <span class="sr-only">Következő</span>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
    name: "ProfileOrders",
    data() {
        return {
            model: [],
            shop: {}
        };
    },
    methods: {
        paginate(page) {
            ApiService.query(
                `${this.url}/order/previous-orders?page=${page}`
            ).then(resp => {
                this.model = resp.data;
            });
        },
        doOrder(id) {
            const self = this;
            ApiService.post(`${this.url}/order/do-previous-order/${id}`).then(
                response => {
                    localStorage.setItem("cart_uuid", response.data.uuid);
                    self.$eventHub.$emit("updateCart", true);
                    self.$router.push({ name: "Checkout" });
                }
            );
        }
    },
    beforeMount() {
        this.$http.get(`${this.url}/shop/get/1`).then(resp => {
            this.shop = resp.data.data;
        });
        ApiService.query(`${this.url}/order/previous-orders`).then(resp => {
            this.model = resp.data;
        });
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    }
};
</script>

<style scoped>
.page-link {
    background: #454d55;
    border: 1px solid #454d55;
    color: white;
}

.page-item.active .page-link {
    background: #e8bb86;
    border-color: #e8bb86;
}
</style>
