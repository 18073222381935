<template>
    <div class="content">
        <section
            id="section-booking"
            class="section booking customCheckout"
            v-if="model && model.shipping && model.billing"
        >
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <form v-on:submit.prevent="changePassword">
                            <h1 class="title text-center">Jelszó módosítás</h1>
                            <div class="my-4">
                                <label>Új jelszó</label>
                                <input
                                    type="password"
                                    name="new_password"
                                    autocomplete="off"
                                    required
                                    v-model="model.password"
                                    class="form-control"
                                />
                                <small
                                    >Az új jelszónak legalább 6 karakter
                                    hosszúnak kell lennie, tartalmaznia kell
                                    kisbetűt, nagybetűt, számot és speciális
                                    karaktert.</small
                                >
                            </div>
                            <div class="my-4">
                                <label>Új jelszó újra</label>
                                <input
                                    type="password"
                                    name="new_password_confirmation"
                                    v-model="model.password_confirm"
                                    required
                                    autocomplete="off"
                                    class="form-control"
                                />
                            </div>
                            <div class="row">
                                <div class="col-md-8"></div>
                                <div class="col-md-4 text-end">
                                    <button
                                        type="submit"
                                        class="bt-orange add-to-cart-btn w-100 mb-2"
                                    >
                                        Mentés
                                    </button>
                                    <small class="text-mini text-grey">
                                        A *-al jelölt mezők kitöltése kötelező
                                    </small>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Profile from "@/views/Profile.vue";
import ApiService from "@/core/services/api.service";
export default {
    name: "ProfilePassword",
    extends: Profile,
    methods: {
        changePassword() {
            if (
                this.model.password &&
                this.model.password_confirm &&
                this.model.password !== "" &&
                this.model.password_confirm !== ""
            ) {
                ApiService.put(`${this.url}/user/change-password`, this.model);
            }
        }
    }
};
</script>

<style scoped></style>
