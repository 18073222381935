<template>
    <div class="content">
        <section
            id="section-booking"
            class="section booking customCheckout"
            v-if="shippings"
        >
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="title text-center">
                            Címeim
                            <i
                                class="fa fa-plus-circle"
                                style="cursor: pointer"
                                v-on:click="addAddress"
                            />
                        </h1>
                        <div class="accordion" role="tablist">
                            <b-card
                                no-body
                                class="mb-1 farmcard"
                                v-for="(item, index) in shippings"
                                v-bind:key="'shipping-' + index"
                            >
                                <b-card-header
                                    header-tag="header"
                                    class="p-1"
                                    role="tab"
                                >
                                    <b-button
                                        block
                                        v-on:click="toggle(index)"
                                        class="farmsargabutton"
                                        >{{ item.lastname }}
                                        {{ item.firstname }},
                                        {{ item.city_name }} {{ item.address }}
                                        <template v-if="item.is_default === 1"
                                            >Alapértelmezett</template
                                        >
                                        <i class="fa fa-pen" />
                                    </b-button>
                                </b-card-header>
                                <b-collapse
                                    :id="'accordion-' + index"
                                    accordion="my-accordion"
                                    :visible="item.visible"
                                    role="tabpanel"
                                >
                                    <b-card-body class="farm-card-body">
                                        <form
                                            v-on:submit.prevent="
                                                updateShippings(item)
                                            "
                                            class="row"
                                        >
                                            <div class="col-md-4">
                                                <label>Vezetéknév*</label>
                                                <input
                                                    type="text"
                                                    name="lastname"
                                                    v-model="item.lastname"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="col-md-4">
                                                <label>Keresztnév*</label>
                                                <input
                                                    type="text"
                                                    name="firstname"
                                                    v-model="item.firstname"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="col-md-4">
                                                <div
                                                    class="custom-control custom-checkbox"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        v-model="
                                                            item.is_default
                                                        "
                                                        :id="
                                                            'is_default' + index
                                                        "
                                                        value="1"
                                                    />
                                                    <label
                                                        class="custom-control-label white"
                                                        :for="
                                                            'is_default' + index
                                                        "
                                                        style="font-size: 15px !important;"
                                                        >Alapértelmezett</label
                                                    >
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <label>Cégnév</label>
                                                <input
                                                    type="text"
                                                    name="company_name"
                                                    v-model="item.company_name"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="col-md-4">
                                                <label>Adószám</label>
                                                <input
                                                    type="text"
                                                    name="vat_number"
                                                    v-model="item.vat_number"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="col-md-4">
                                                <label>Telefonszám*</label>
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    v-model="item.phone"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div
                                                class="col-md-4"
                                                v-if="
                                                    cities && cities.length > 0
                                                "
                                            >
                                                <label>Település*</label>
                                                <select
                                                    class="custom-select"
                                                    id="billing_city"
                                                    v-model="item.city"
                                                >
                                                    <option :value="null"
                                                        >Kérem válasszon</option
                                                    >
                                                    <option
                                                        v-for="(item,
                                                        index) in cities"
                                                        v-bind:key="
                                                            'city-' + index
                                                        "
                                                        :value="item.id"
                                                        >{{ item.name }}</option
                                                    >
                                                </select>
                                            </div>
                                            <div class="col-md-4">
                                                <label>Utca/Házszám*</label>
                                                <input
                                                    type="text"
                                                    name="address"
                                                    v-model="item.address"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="col-md-4">
                                                <button
                                                    type="submit"
                                                    class="bt-orange add-to-cart-btn w-100 mb-2"
                                                >
                                                    Mentés
                                                </button>
                                                <small
                                                    class="text-mini text-grey"
                                                >
                                                    A *-al jelölt mezők
                                                    kitöltése kötelező
                                                </small>
                                                <hr />
                                                <a
                                                    href="#"
                                                    v-on:click.prevent="
                                                        deleteAddress(item)
                                                    "
                                                    style="color: red"
                                                    >Törlés</a
                                                >
                                            </div>
                                        </form>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section
            id="section-booking"
            class="section booking customCheckout"
            v-if="model && model.shipping && model.billing"
        >
            <div class="container" style="margin-top: 30px">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <h1 class="title text-center">E-mail cím módosítása</h1>
                        <form v-on:submit.prevent="updateProfile">
                            <!--<div class="my-4">
                                <label>Vezetéknév*</label>
                                <input
                                    type="text"
                                    name="lastname"
                                    v-model="model.shipping.lastname"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label>Keresztnév*</label>
                                <input
                                    type="text"
                                    name="firstname"
                                    v-model="model.shipping.firstname"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label>Telefonszám*</label>
                                <input
                                    type="text"
                                    name="phone"
                                    v-model="model.shipping.phone"
                                    class="form-control"
                                />
                            </div>-->
                            <div class="my-4">
                                <label>E-mail*</label>
                                <input
                                    type="email"
                                    name="email"
                                    v-model="model.email"
                                    class="form-control"
                                />
                            </div>
                            <div class="row">
                                <div class="col-md-8"></div>
                                <div class="col-md-4 text-end">
                                    <button
                                        type="submit"
                                        class="bt-orange add-to-cart-btn w-100 mb-2"
                                    >
                                        Mentés
                                    </button>
                                    <small class="text-mini text-grey">
                                        A *-al jelölt mezők kitöltése kötelező
                                    </small>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "ProfileData",
    data() {
        return {
            model: {
                billing: {},
                shipping: {},
                same_shipping: true
            },
            shippings: [],
            cities: [],
            isCompany: false,
            isSameShipping: true
        };
    },
    beforeMount() {
        this.getCities();
        if ($cookies.get("id_token") && $cookies.get("user")) {
            this.getUserData();
        }
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    methods: {
        deleteAddress(item) {
            const self = this;
            if (item.id) {
                ApiService.delete(
                    `${self.url}/user/delete-shipping-address/${item.id}`
                ).then(resp => {
                    this.shippings = resp.data.data;
                });
            } else {
                this.shippings.splice(-1);
            }
        },
        toggle(index) {
            this.$root.$emit("bv::toggle::collapse", "accordion-" + index);
        },
        addAddress() {
            this.shippings.push({
                id: null,
                firstname: null,
                lastname: null,
                city: null,
                city_name: null,
                address: null,
                is_default: 0,
                vat_number: null,
                company_name: null,
                visible: true
            });
        },
        getCities() {
            this.$http.get(`${this.url}/city/list`).then(response => {
                this.cities = response.data.data;
            });
        },
        changeSameShip() {
            if (this.isSameShipping) {
                this.model.billing.country = this.model.shipping.country;
                this.model.billing.city = this.model.shipping.city;
                this.model.billing.zip = this.model.shipping.zip;
                this.model.billing.address = this.model.shipping.address;
                this.model.same_shipping = true;
            } else {
                this.model.same_shipping = false;
            }
        },
        getUserData() {
            const self = this;
            ApiService.get(`${self.url}/user/shipping-addresses`).then(
                response => {
                    self.shippings = response.data.data;
                }
            );
            ApiService.get(`${self.url}/user/profile`).then(response => {
                self.model = response.data.data;
                if (
                    self.model.shipping.country ===
                        self.model.billing.country &&
                    self.model.shipping.zip === self.model.billing.zip &&
                    self.model.shipping.city === self.model.billing.city &&
                    self.model.shipping.address === self.model.billing.address
                ) {
                    self.isSameShipping = true;
                    self.model.same_shipping = true;
                } else {
                    self.isSameShipping = false;
                    self.model.same_shipping = false;
                }

                if (self.model.billing.vat_number) {
                    self.isCompany = true;
                }
            });
        },
        updateShippings(item) {
            if (item.id) {
                ApiService.put(
                    `${this.url}/user/update-shipping-address/${item.id}`,
                    item
                ).then(resp => {
                    this.shippings = resp.data.data;
                });
            } else {
                ApiService.post(
                    `${this.url}/user/create-shipping-address`,
                    item
                ).then(resp => {
                    this.shippings = resp.data.data;
                });
            }
        },
        updateProfile() {
            ApiService.put(
                `${this.url}/user/update-frontend-profile`,
                this.model
            );

            if (
                this.model.password &&
                this.model.password_confirm &&
                this.model.password !== "" &&
                this.model.password_confirm !== ""
            ) {
                ApiService.put(`${this.url}/user/change-password`, this.model);
            }
        }
    }
};
</script>

<style>
.farmsargabutton {
    background: #e8bb86 !important;
    border: 1px solid #e8bb86 !important;
}

.farmcard {
    background: black;
}

.farm-card-body {
    border-bottom: 1px solid white;
}
</style>
