<template>
    <div class="content">
        <section id="section-booking" class="section booking customCheckout">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <b-tabs
                            pills
                            card
                            vertical
                            nav-wrapper-class="mobile-friendly farmsarga"
                            nav-class="farmsarga"
                            active-tab-class="farmsarga"
                            active-nav-item-class="farmsarga2"
                        >
                            <b-tab title="Címeim" active
                                ><b-card-text> <Profile /> </b-card-text
                            ></b-tab>
                            <b-tab title="Rendeléseim"
                                ><b-card-text> <ProfileOrders /> </b-card-text
                            ></b-tab>
                            <b-tab title="Jelszó módosítás"
                                ><b-card-text> <ProfilePassword /> </b-card-text
                            ></b-tab>
                            <b-tab
                                title="Kijelentkezés"
                                v-on:click.prevent="logout"
                                ><b-card-text
                                    >Kijelentkezés folyamatban...</b-card-text
                                ></b-tab
                            >
                        </b-tabs>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Profile from "@/views/Profile.vue";
import ProfileOrders from "@/views/ProfileOrders.vue";
import ProfilePassword from "@/views/ProfilePassword.vue";
export default {
    name: "ProfileBase",
    components: { Profile, ProfileOrders, ProfilePassword },
    methods: {
        logout() {
            $cookies.remove("user");
            $cookies.remove("id_token");
            document.location.href = "/";
        }
    }
};
</script>

<style>
@media only screen and (max-width: 1100px) {
    .mobile-friendly {
        width: 100%;
    }
}

.farmsarga a {
    color: white !important;
}

.farmsarga2 {
    background: #e8bb86 !important;
    border: 1px solid #e8bb86 !important;
    border-radius: 0 !important;
    font-weight: bold;
}
</style>
