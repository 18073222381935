var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('section',{staticClass:"section booking customCheckout",attrs:{"id":"section-booking"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h1',{staticClass:"title text-center"},[_vm._v(" Korábbi rendeléseim ")]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-dark"},[_vm._m(0),_c('tbody',_vm._l((_vm.model.data),function(item,index){return _c('tr',{key:'previous-orders-' + index},[_c('td',[_vm._v(_vm._s(item.created_at))]),_c('td',[_vm._v(" "+_vm._s(item.shipping_lastname)+" "+_vm._s(item.shipping_firstname)),_c('br'),_vm._v(_vm._s(item.shipping_city_name)),_c('br'),_vm._v(_vm._s(item.shipping_address)+" ")]),_c('td',_vm._l((item.items),function(item2,index2){return _c('div',{key:'order-items-' + index2},[_c('b',[_vm._v(_vm._s(item2.product.name))]),_c('br'),_c('small',{domProps:{"innerHTML":_vm._s(item2.extras)}}),_c('br'),_c('small',{domProps:{"innerHTML":_vm._s(item2.comment)}})])}),0),_c('td',[_vm._v(" "+_vm._s(item.order_total_formatted)+" ")]),_c('td',[(_vm.shop && _vm.shop.is_open)?_c('button',{staticClass:"menu-btn",staticStyle:{"border-radius":"5px","float":"none !important"},on:{"click":function($event){return _vm.doOrder(item.id)}}},[_vm._v(" Rendelés ")]):_vm._e()])])}),0)])]),(_vm.model && _vm.model.meta && _vm.model.links)?_c('nav',{attrs:{"aria-label":"Page navigation example"}},[_c('ul',{staticClass:"pagination"},[_c('li',{staticClass:"page-item"},[_c('button',{staticClass:"page-link",attrs:{"href":"#","aria-label":"Előző","disabled":_vm.model.meta.current_page < 2},on:{"click":function($event){return _vm.paginate(
                                            _vm.model.meta.current_page - 1
                                        )}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("«")]),_c('span',{staticClass:"sr-only"},[_vm._v("Előző")])])]),(_vm.model.meta.current_page > 1)?_c('li',{staticClass:"page-item",on:{"click":function($event){return _vm.paginate(_vm.model.meta.current_page - 1)}}},[_c('button',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v(" "+_vm._s(_vm.model.meta.current_page - 1)+" ")])]):_vm._e(),_c('li',{staticClass:"page-item active"},[_c('button',{staticClass:"page-link"},[_vm._v(" "+_vm._s(_vm.model.meta.current_page)+" ")])]),(
                                    _vm.model.meta.current_page !==
                                        _vm.model.meta.last_page
                                )?_c('li',{staticClass:"page-item"},[_c('button',{staticClass:"page-link",attrs:{"href":"#"},on:{"click":function($event){return _vm.paginate(
                                            _vm.model.meta.current_page + 1
                                        )}}},[_vm._v(" "+_vm._s(_vm.model.meta.current_page + 1)+" ")])]):_vm._e(),_c('li',{staticClass:"page-item"},[_c('button',{staticClass:"page-link",attrs:{"href":"#","aria-label":"Következő","disabled":_vm.model.meta.current_page ===
                                            _vm.model.meta.last_page},on:{"click":function($event){return _vm.paginate(
                                            _vm.model.meta.current_page + 1
                                        )}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("»")]),_c('span',{staticClass:"sr-only"},[_vm._v("Következő")])])])])]):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Dátum")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Cím")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Tételek")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Összeg")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Újrarendelés")])])])}]

export { render, staticRenderFns }